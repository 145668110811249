import React from 'react';

import { useParams } from 'react-router-dom';

import styles from './FloorPlan.module.css';

import { floorPlansArray } from '../../databases/Plans';

const FloorPlan = () => {

  let {plan} = useParams();

  const currentPlan = floorPlansArray.filter(({name}) => (name === plan))[0]

  return (
    <div className={`Page ${styles.FloorPlan}`}>
        <h1>{plan}</h1>
        { currentPlan.mapUrl && <div className={styles.MapView}>
            <h3>Take an Interactive 3D Tour</h3>
            <iframe width="100%" height="480" src={currentPlan.mapUrl} frameborder="0" allowfullscreen allow="xr-spatial-tracking"></iframe>
        </div>}
        <div className={styles.Images}>
            {
                floorPlansArray.filter(({name}) => (
                    name === plan
                )).map((result) => (
                    result.images.map((image) => (
                        <img src={image}/>
                    ))
                ))
            }
        </div>
    </div>
  )
}

export default FloorPlan;