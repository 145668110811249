import React from 'react';

import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.Footer}>
    <span>Copyright Ⓒ Jumpstart Investments Inc {new Date().getFullYear()}</span>
    </footer>
  )
}

export default Footer