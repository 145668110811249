import React, { useState, useRef } from 'react';

import emailjs from '@emailjs/browser';

import MessageSent from '../../components/MessageSent/MessageSent';

import styles from './Contact.module.css';

const Contact = () => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [messageState, setMessageState] = useState('empty');

  const form = useRef();

  const validateForm = () => {
    let isValid = true;

    const errors = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      message: ''
    };

    // Validate First Name
    if (formValues.firstName.trim() === '') {
      isValid = false;
      errors.firstName = 'First Name is required';
    }

    // Validate Last Name
    if (formValues.lastName.trim() === '') {
      isValid = false;
      errors.lastName = 'Last Name is required';
    }

    // Validate Email
    if (formValues.email.trim() === '') {
      isValid = false;
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      isValid = false;
      errors.email = 'Invalid email address';
    }

    // Validate Phone
    if (formValues.phone.trim() === '') {
      isValid = false;
      errors.phone = 'Phone is required';
    } else if (!/^\d{10}$/.test(formValues.phone)) {
      isValid = false;
      errors.phone = 'Phone number should be 10 digits';
    }

    // Validate Message
    if (formValues.message.trim() === '') {
      isValid = false;
      errors.message = 'Message is required';
    }

    setFormErrors(errors);
    return isValid;
  };

  function handleOnChange(e) {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateForm()) {
      // Form submission logic goes here
      emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then((result) => {
          setMessageState('sent')
          const messageTimeOut = setTimeout(() => setMessageState('empty'), 3000);
      }, (error) => {
          setMessageState('failed')
          const messageTimeOut = setTimeout(() => setMessageState('empty'), 3000);
      });
    } else {
      console.log('Form contains errors');
    }
  }

  return (
    <div className={`Page ${styles.Contact}`}>
      <MessageSent messageState={messageState}/>
      <h1 className='Title'>GET IN TOUCH</h1>
      <p>Got questions? Fill out the form below and we'll get back to you soon.</p>
      <br/>
      <form ref={form} onSubmit={handleSubmit}>
        <label>First Name</label>
        <input
          name='firstName'
          value={formValues.firstName}
          onChange={handleOnChange}
        />
        {formErrors.firstName && <p className={styles.Error}>{formErrors.firstName}</p>}

        <label>Last Name</label>
        <input
          name='lastName'
          value={formValues.lastName}
          onChange={handleOnChange}
        />
        {formErrors.lastName && <p className={styles.Error}>{formErrors.lastName}</p>}

        <label>Email</label>
        <input
          type='email'
          name='email'
          value={formValues.email}
          onChange={handleOnChange}
        />
        {formErrors.email && <p className={styles.Error}>{formErrors.email}</p>}

        <label>Phone</label>
        <input
          name='phone'
          value={formValues.phone}
          onChange={handleOnChange}
        />
        {formErrors.phone && <p className={styles.Error}>{formErrors.phone}</p>}

        <label>Message</label>
        <textarea
          name='message'
          rows={10}
          value={formValues.message}
          onChange={handleOnChange}
        />
        {formErrors.message && <p className={styles.Error}>{formErrors.message}</p>}

        <button className='Purple'>SUBMIT</button>
      </form>
      <div className={styles.ContactInfo}>
        <div>
          <img className={styles.Icon} src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Icons%2Ficons8-location-48.png?alt=media&token=64270782-1126-4fb1-9bec-c8cbce6187e3"/>
          <p>405 Western Boulevard Suite B Jacksonville, NC 28546</p>
        </div>
        <div>
          <img className={styles.Icon} src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Icons%2Ficons8-phone-48.png?alt=media&token=9b92e675-1bd1-4ed1-88be-ac4d757d2f4b"/>
          <p>(910) 389 9394</p>
        </div>
        <div>
          <img className={styles.Icon} src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Icons%2Ficons8-email-48.png?alt=media&token=38ade24e-5b75-44cf-8716-35c76b20bef8"/>
          <p>admin@jumpstartinvestmentsinc.com</p>
        </div>
      </div>
    </div>
  );
};


export default Contact;