import React from 'react';

import styles from './Home.module.css';

const Home = () => {

  const imagesArray = [
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240402838_1257558768013829_2184544431491364078_n.jpg?alt=media&token=4cea0cc8-9717-421b-9ef8-fb03440f0ddf",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240452811_1257558264680546_5379086851134784625_n.jpg?alt=media&token=c9d6bfe5-d780-423b-aef7-92ecd01f3d4e",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/290606812_1459334367836267_2498721943405760475_n.jpg?alt=media&token=36a86f11-af5c-4a10-8745-9e0ced768dfe",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/291400658_1459333481169689_560761663683722195_n.jpg?alt=media&token=601f1b63-2966-4838-a8d9-b27b2c6b0db8",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240121176_1257558581347181_1511220941258605662_n.jpg?alt=media&token=c42bc0bd-123c-4faa-b7a0-5cb59612770d",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/354440055_824525449348013_8326019810098858136_n.jpg?alt=media&token=5ea41542-a12f-4b1b-8429-d0de62e76223"
  ];

  return (
    <div className={styles.Home}>
        <section className={styles.Hero}>
            <div className={styles.Text}>
              <h1>BUILD.  RENOVATE.  INVEST.</h1>
              <a href="/contact-us">
                <button className='WhiteBorder'>GET IN TOUCH</button>
              </a>
            </div>
        </section>
        <section className={styles.About}>
            <h1 className='Title'>WE BUILD HOMES IN 90-120 DAYS!</h1>
            <h3>Jumpstart Investments Inc is a comprehensive design-build company that caters to all your home requirements, providing you with a convenient and all-encompassing solution.</h3>
            <p>No matter the scope of your project, whether it involves transforming a fixer-upper or bringing your dream home to life, our team of skilled professionals is prepared to deliver prompt, top-notch service, ensuring your peace of mind throughout the process.</p>
            <a href='/about-us'>
              <button className='Purple'>LEARN MORE</button>
            </a>
        </section>
        <section className={styles.Gallery}>
            {
                imagesArray.map((image, index) => (
                    <img key={index} src={image}/>
                ))
            }
        </section>
        <section className={styles.Contact}>
          <h1 className='Title'>READY TO GET STARTED?</h1>
          <a href='/contact-us'>
            <button className='Purple'>CONTACT US</button>
          </a>
        </section>
    </div>
  )
}

export default Home