

import styles from './MessageSent.module.css'

const MessageSent = ({messageState}) => {

  return (
    <div className={`${styles.MessageSent} ${messageState === 'sent' && styles.Success} ${messageState === 'failed' && styles.Failed}`} style={{ top: messageState === 'empty' && '-50px' }}>
        { messageState === 'sent' && <p>Message sent successfully!</p> }
        { messageState === 'failed' && <p>Message failed to send. Please try again later.</p> }
    </div>
  )
}

export default MessageSent;