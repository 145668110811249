import './App.css';

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

// Pages
import Home from './pages/Home/Home';
import About from './pages/About/About';
import FloorPlans from './pages/FloorPlans/FloorPlans';
import FloorPlan from './pages/FloorPlan/FloorPlan';
import Contact from './pages/Contact/Contact';
import FAQ from './pages/FAQ/FAQ';
import Gallery from './pages/Gallery/Gallery';

// Components
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import MobileNavbar from './components/MobileNavbar/MobileNavbar';

function App() {
  return (
    <Router>
    <div className="App">
      <Navbar />
      <MobileNavbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about-us" element={<About />}></Route>
        <Route path="/floor-plans" element={<FloorPlans/>}></Route>
        <Route path="/floor-plans/:plan" element={<FloorPlan />}/>
        <Route path="/contact-us" element={<Contact />}></Route>
        <Route path="/faq" element={<FAQ/>}/>
        <Route path="/gallery" element={<Gallery />}/>
      </Routes>
      <Footer />
    </div>
    </Router>
  );
}

export default App;
