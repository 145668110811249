import React from 'react';

import styles from './Navbar.module.css';

const Navbar = () => {
  return (
    <nav className={styles.Navbar}>
        <ul>
            <li>
                <a href="/">
                    <img className='Logo' src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/222813229_1234894823613557_7820463364771789591_n.jpg?alt=media&token=4c032980-beb9-4d22-a123-a6672f1f768a"/>
                </a>
            </li>
            <li>
                <a href="/about-us">About Us</a>
            </li>
            <li>
                <a href="/floor-plans">Floor Plans</a>
            </li>
            <li>
                <a href="/gallery">Gallery</a>
            </li>
            <li>
                <a href="/contact-us">Contact Us</a>
            </li>
            <li>
                <a href="/faq">FAQs</a>
            </li>
            <li>
                <a href="https://www.facebook.com/jumpstartinvestments" target='_blank' rel="noreferrer">Facebook</a>
            </li>
        </ul>
    </nav>
  )
}

export default Navbar