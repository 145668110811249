import React from 'react';

import styles from './Gallery.module.css';

const Gallery = () => {

  const imagesArray = [
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(1%20of%209)-min.jpg?alt=media&token=96f8d3d6-0eda-4672-9c8c-37e2d5180331",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(12%20of%2077)-min.jpg?alt=media&token=f34be264-bb25-467f-96ca-702d9f8125c8",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(2%20of%2077)-min.jpg?alt=media&token=08e898d1-a95e-41d2-925c-6dce7311ea91",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(25%20of%2077)-min.jpg?alt=media&token=5b1b27bd-f4a8-4272-83b0-304910a51bb5",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(3%20of%2077)-min.jpg?alt=media&token=784078bd-7e12-4575-8dd9-de482da0c460",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(35%20of%2077)-min.jpg?alt=media&token=6045587f-5cf8-431c-af7c-36cbd22f2296",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(43%20of%2077)-min.jpg?alt=media&token=79c86293-8b37-45f3-a5f1-ae9f45514e10",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(5%20of%2077)-min.jpg?alt=media&token=2ac4ea59-bad1-4317-96e8-f810f0b765aa",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(55%20of%2077)-min.jpg?alt=media&token=6b5f0564-c9bf-4cf4-af71-9e284577ffab",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(56%20of%2077)-min.jpg?alt=media&token=978134d4-31d6-47bf-99e0-e3ad2fdb60ec",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(63%20of%2077)-min.jpg?alt=media&token=20e79883-0616-40e1-9615-02664447ae1b",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(64%20of%2077)-min.jpg?alt=media&token=2ec76217-bbb5-4cd1-8c72-07a7e516cc02",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(76%20of%2077)-min.jpg?alt=media&token=9eb77d59-9f03-4f99-a1b3-811159db66d6",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(8%20of%2077)-min.jpg?alt=media&token=0605ad22-a04e-4c63-9458-3d9581541185",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/Gallery%2Fjumpstart%20(9%20of%209)-min.jpg?alt=media&token=c786b3a6-d152-4ff2-b6a4-036ce4cfe985",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240153447_1257558538013852_6590819551920668188_n.jpg?alt=media&token=ae0942e4-6393-4a1c-9e4a-7a096ef8e0dc",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240587241_1257558678013838_4878968377492046225_n.jpg?alt=media&token=e886e5b7-d55a-4525-bcac-b2689015ae82",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/291351784_1459333764502994_1690235356389812851_n.jpg?alt=media&token=e9277fad-7dc1-4a73-8947-fa093105a1fa",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/296792218_1480493932386977_1339076377138883732_n.jpg?alt=media&token=47d9e4bc-4b47-4b3d-8c51-53dc6ab1a779",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/240452718_1257558621347177_6682051297549921665_n.jpg?alt=media&token=fcb7c853-ebff-473e-8f63-a0e66da0a4cc",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/261036147_1319513021818403_4117856309888465095_n.jpg?alt=media&token=ba58eea3-cf96-40bc-bbd2-2d23f9aeae70",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/290303403_1459333647836339_8430925108913418967_n.jpg?alt=media&token=fee07804-cb94-4cb4-b572-b1ca98b3d962",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/290923004_1459334204502950_6057550346886144647_n.jpg?alt=media&token=ea0b4256-55b0-4c86-a9a6-9c6d3cf86f50",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/291277384_1459333204503050_3346672145850122936_n.jpg?alt=media&token=5b77517e-7a81-44bd-a81e-e5e8da0e346a",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/291469629_1459334144502956_144440046225446976_n.jpg?alt=media&token=06e0e40c-1f67-45b9-948f-176f8e50c09b",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/315205968_654828662984360_1775150869666795861_n.jpg?alt=media&token=f6d6fc9f-0b9d-4bcb-9f14-e12beb825d1d",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/326880403_1052822726110035_357542701004476259_n.jpg?alt=media&token=eb793620-f257-4faf-b16c-5f97fcc23b7f",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/344864357_894306671635843_535520171172599424_n.jpg?alt=media&token=a0b99c97-f84b-4e3e-b783-86d3369a970d",
    "https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/355331538_826481332485758_6989971210019529903_n.jpg?alt=media&token=4f0bd22b-7237-49d7-98da-b94a2a6cf923",
    
  ];

  return (
    <div className={`${styles.Gallery}`}>
        <h1 className='Title'>GALLERY</h1>
        <div className={styles.Images}>
        {
            imagesArray.map((image, index) => (
                <img key={index} src={image}/>
            ))
        }
        </div>
    </div>
  )
}

export default Gallery;