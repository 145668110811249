import React from 'react';

import styles from './About.module.css';

const About = () => {
  return (
    <div className={`Page ${styles.About}`}>
        <section>
            <h1 className='Title'>ABOUT US</h1>
            <h3>We are a full-services building company that can bring your ideas to reality. </h3>
            <p>Whether your still planning out your dream house project or looking to give your existing home a makeover, 
            our team of experts are ready to step in with solutions. Benefit from our years of experience and knowledge to curate living spaces that perfectly reflect who you are.</p>
        </section>
        <section>
            <div className={styles.TwoCol}>
                <div className={styles.ColOne}>
                    <h2>Hi! My name is Hallie.</h2>
                    <br/>
                    <p>I started this company with the intention of bringing something new to the outdated process of building and renovating new homes. 
                    Construction projects, whether big or small, can be quite stressful and take up way more time than they need to. 
                    With Jumpstart Investments, I wanted to change that for good. </p>
                    <br/>
                    <p>Convenience is just as important as quality. 
                    When you hire us, you get the dream team. We do it all and we do it fast, which means you don’t have to go through the hassle of looking for a realtor, architect, designer and builder and you don’t have to wait around.</p>
                    <br/>
                    <p>My team and I are passionate about making your dreams come true. Get in touch and you’ll see how easy it is to get started!</p>
                    <br/>
                    <h3>HALLIE DABO</h3>
                    <h5>CEO / FOUNDER</h5>
                </div>
                <div className={styles.ColTwo}>
                    {/* <img src="https://i.ibb.co/gyY2zMQ/290820002-1460072067762497-3431251680213119062-n.jpg"/> */}
                    <img src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/320944264_828101088292453_5935608140570542162_n.jpg?alt=media&token=3d2bbaaa-92f2-4540-b849-ce9c4763e542" alt="CEO of Jumpstart Investments Inc Halimah Dabo"/>
                </div>
            </div>
            <div className={styles.Cta}>
                <a href="/contact-us">
                    <button className='Purple'>WORK WITH US</button>
                </a>
            </div>
        </section>
        <section>
            <h1>WANT TO LEARN MORE ABOUT MY ENTREPRENEURIAL JOURNEY?</h1>
            <div className={styles.TwoCol}>
                <div className={styles.ColOne}>
                    <img src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/350646487_6021644847961868_3734360938930897475_n.jpg?alt=media&token=18e68785-b6d5-49e4-b680-b4a60e5ecb69"/>
                    <a href="https://tinyurl.com/y2trrs3r" target='_blank' rel='noreferrer'>
                        <button className='Purple'>PRE-ORDER BOOK</button>
                    </a>
                </div>
                <div className={styles.ColTwo}>
                    <h3><a href="https://tinyurl.com/y2trrs3r" target='_blank' rel='noreferrer'><u>Check out my book</u></a> "Building in a Man’s World: Road to Millions"</h3>
                    <div className={styles.Synopsis}>
                    <p>'Hallie Dabo has never known how to take “no” for an answer. From serving as a United States Marine to being the only African American Woman General Contractor building custom homes in Jacksonville, North Carolina, she is not afraid to take risks to get what she wants.</p>
                    <br/>
                    <p>Born in London and raised in Ethiopia, Hallie is working tirelessly to build a phenomenal legacy through her one-of-a-kind business. </p>
                    <br/>
                    <p><strong>“Building in a Man’s World: Road to Millions”</strong> is a heartfelt story colorfully detailing the  challenges of breaking barriers throughout Hallie's unique journey of success. It is one of remarkable determination and true grit.</p>
                    <br/>
                    <p>This practical and fundamental business guide shares the wisdom of a woman on a mission to set a new precedent for what is possible when you set your mind and heart to the beat of your own drum.'</p>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default About