import { useState } from 'react';
import Hamburger from 'hamburger-react';

import styles from './MobileNavbar.module.css';


const MobileNavbar = () => {
  const [menuOpen, setMenuOpenState] = useState(false);

  function toggleMenu() {
    setMenuOpenState(!menuOpen)
  };

  return (
    <nav className={styles.MobileNavbar}>
        <ul>
            <li>
                <a href="/">
                    <img className='Logo' src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/222813229_1234894823613557_7820463364771789591_n.jpg?alt=media&token=4c032980-beb9-4d22-a123-a6672f1f768a"/>
                </a>
            </li>
            <li>
                <Hamburger toggled={menuOpen} toggle={toggleMenu} size={24} color="#fff"/>
            </li>
        </ul>
        <section className={styles.Menu} style={{ display: menuOpen? 'block': 'none' }}>
            <ul>
                <li>
                    <a href="/about-us">ABOUT US</a>
                </li>
                <li>
                    <a href="/floor-plans">FLOOR PLANS</a>
                </li>
                <li>
                    <a href="/gallery">GALLERY</a>
                </li>
                <li>
                    <a href="/contact-us">CONTACT US</a>
                </li>
                <li>
                    <a href="/faq">FAQs</a>
                </li>
                <li>
                    <a href="https://www.facebook.com/jumpstartinvestments" target='_blank' rel='noreferrer'>FACEBOOK</a>
                </li>
            </ul>
        </section>
    </nav>
  )
}

export default MobileNavbar