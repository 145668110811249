import React from 'react';

import styles from './FloorPlans.module.css';

import { floorPlansArray } from '../../databases/Plans';

const FloorPlans = () => {
  return (
    <div className={`Page ${styles.FloorPlans}`}>
        <h1 className='Title'>FLOOR PLANS</h1>
        <div className={styles.Plans}>
            {
                floorPlansArray.map(({name, images}) => (
                    <a href={`/floor-plans/${name}`}>
                        <div className={styles.Plan}>
                            <h3>{name.toUpperCase()}</h3>
                            <div className={styles.PlanImages}>
                                <img src={images[0]} alt={name}/>
                            </div>
                        </div>
                    </a>
                ))
            }
        </div>
    </div>
  )
}

export default FloorPlans