import React from 'react';

import styles from './FAQ.module.css';

const FAQ = () => {

  const faqArray = [
    {
        q: "What if I don't already have land?",
        a: "Not a problem! In fact, a lot of our client's don't have land when they first approach us. We can help you find the right property that suits your needs, thanks to our extensive network of realtors. Whether you want to live in the suburbs or prefer to have your own secluded bit of countryside, we’ve got you covered."
    },
    {
        q: "Will you build from my plan?",
        a: "We have a variety of existing floor plans that are tried and true and cover a wide variety of needs. However, we can also design custom floor plans from the ground up if needed. We’re committed to bringing your vision to life and will work with you every step of the way."
    },
    {
        q: "What is your build time?",
        a: "Our typical build time is between 90-120 days."
    },
    {
        q: "What kind of warranty do you offer on homes?",
        a: "We offer a one-year Builder's Warranty on all of our homes. This includes coverage for workmanship and materials. For more information, reach out to us."
    },
    {
        q: "Is there a particular real estate broker you work with?",
        a: `Yes. We recommend Johanna Norris Bailey with Signature Residential Properties who we have worked with extensively over the years.`
    }
  ];
  return (
    <div className={`Page ${styles.FAQ}`}>
        <h1 className='Title'>FREQUENTLY ASKED QUESTIONS</h1>
        {
            faqArray.map(({q, a}, index) => (
                <div key={index} className={styles.Item}>
                    <h3>{q}</h3>
                    <p>{a}</p>
                    <hr />
                </div>
            ))
        }
        <div className={styles.Item}>
            <h3>How can I finance my construction project?</h3>
            <p>{`We recommend our trusted Mortgage Loan Officer Thomas Baldwin at Alpha Mortgage for all of your financing needs:`}</p>
            <br/>
            <p><strong>Mobile:</strong> (910)330-3384</p>
            <p><strong>Office:</strong> (910)839-0124</p>
            <p>thomas.baldwin@alphamortgage.com</p>
            <a className='InlineLink' href="https://www.alphamortgage.com/loan-officer/thomas-baldwin/" target='_blank' rel='noreferrer'>Website</a>
            <br/>
            <br/>
            <img width='200px' src="https://firebasestorage.googleapis.com/v0/b/jumpstartinc-38142.appspot.com/o/AlphaMortgageLogo-blue-no-tagline-copy.webp?alt=media&token=3582587e-a089-41f7-b182-f2557ba14304" />
        </div>
        <h1>GOT ANY ADDITIONAL QUESTIONS?</h1>
        <br/>
        <a href="/contact-us">
            <button className='Purple'>CONTACT US</button>
        </a>
    </div>
  )
}

export default FAQ;